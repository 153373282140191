import { Order } from '../types';

export const fetchOrders = async (token: string): Promise<Order[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/orders`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};
