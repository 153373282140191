import {} from 'solar-icon-set/essentionalui';

import {
  Button,
  Card,
  Divider,
  Skeleton,
  useDisclosure,
} from '@nextui-org/react';
import { Order, ResellerInfo } from '../types';
// Orders.tsx
import React, { useEffect, useState } from 'react';

import CreateOrderModal from './CreateOrderModal';
import { fetchOrders } from '../services/orderService';
import { fetchResellerInfo } from '../services/resellerService';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';

const Orders: React.FC = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [orders, setOrders] = useState<Order[]>([]);
  const [resellerInfo, setResellerInfo] = useState<ResellerInfo>();
  const [error, setError] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSingleTrialUnit, setIsSingleTrialUnit] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!isAuthenticated) {
        console.log('User is not authenticated, prompting login.');
        return;
      }

      try {
        const token = await getAccessTokenSilently();
        const orders = await fetchOrders(token);
        const resellerInfo = await fetchResellerInfo(token);
        setOrders(orders);
        setResellerInfo(resellerInfo);
        setIsLoaded(true);
      } catch (error: any) {
        setError(error?.message || 'An error occurred');
      }
    };

    fetchData();
  }, [getAccessTokenSilently, isAuthenticated]);

  const handleCreateOrder = async (
    quantity: number,
    discount_percentage: number,
    discount_name: string
  ) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/draft-order`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            variant_id: 42451734200499,
            quantity,
            discount_percentage,
            discount_name,
            customer_id: resellerInfo?.shopify_customer_id ?? '',
          }),
        }
      );
      if (!response.ok) {
        throw new Error('Failed to create draft order');
      }
      const data = await response.json();
      const invoiceUrl = data.invoice_url;
      if (invoiceUrl) {
        // Redirect to the invoice URL
        window.location.href = invoiceUrl;
      } else {
        setError('No invoice URL found in the response');
      }
    } catch (err: any) {
      setError(err.message || 'An error occurred while creating the order');
    }
  };

  return (
    <div>
      <Skeleton isLoaded={isLoaded} className="rounded-lg">
        <h2 className="text-2xl md:text-2xl font-bold mb-2">
          Welcome, {resellerInfo?.first_name}
        </h2>
        <p className="text-md font-semibold text-gray-600">
          {resellerInfo?.company_name}
        </p>
      </Skeleton>

      <Divider className="my-4" />

      {error ? (
        <p className="text-red-500 mt-4">Error fetching orders: {error}</p>
      ) : (
        <>
          <div className="flex flex-row items-center gap-5">
            <h3 className="text-2xl md:text-xl font-bold my-4">Orders</h3>
            <Skeleton isLoaded={isLoaded} className="rounded-lg">
              <div className="flex gap-3">
                <Button
                  color="primary"
                  onClick={() => {
                    onOpen();
                    setIsSingleTrialUnit(false);
                  }}
                  className="bg-gradient-to-r from-gray-800 to-airlock-purple rounded-2xl"
                >
                  Place New Order
                </Button>
                {orders.length === 0 ? (
                  <Button
                    color="primary"
                    onClick={() => {
                      onOpen();
                      setIsSingleTrialUnit(true);
                    }}
                    className="bg-gradient-to-r from-gray-800 to-airlock-purple rounded-2xl"
                  >
                    Trial Unit
                  </Button>
                ) : undefined}
              </div>
            </Skeleton>
          </div>

          <ul className="mt-4 space-y-5">
            {orders.length > 0 ? (
              orders.map(order => (
                <Card className="p-4" key={order.id}>
                  <li className="flex flex-col gap-2">
                    <h3 className="font-bold flex flex-row gap-3">
                      Order #{order.order_number} -{' '}
                      {moment(order.created_at).format('MMMM DD, YYYY')}
                    </h3>
                    <p>
                      {order.line_items[0].name} x{' '}
                      {order.line_items[0].quantity}
                    </p>
                    <p>Total: ${order.total_price}</p>
                    <p>
                      Shipping Address:{' '}
                      {order?.shipping_address?.address1 &&
                      order?.shipping_address?.city &&
                      order?.shipping_address?.province
                        ? `${order.shipping_address.address1}, ${order.shipping_address.city}, ${order.shipping_address.province}`
                        : 'No shipping address'}
                    </p>
                    <p>
                      Tracking #:{' '}
                      {order.fulfillments && order.fulfillments.length > 0
                        ? (order.fulfillments[0].tracking_number ??
                          'No tracking number')
                        : 'No tracking number'}
                    </p>
                  </li>
                </Card>
              ))
            ) : (
              <Skeleton isLoaded={isLoaded} className="rounded-lg">
                <Card className="p-4">
                  <p>No orders found. Place your first order to get started!</p>
                </Card>
              </Skeleton>
            )}
          </ul>
          <CreateOrderModal
            isOpen={isOpen}
            onClose={onClose}
            isSingleTrialUnit={isSingleTrialUnit}
            resellerInfo={resellerInfo}
            onSubmit={(
              qty: number,
              discount_percentage: number,
              discount_name: string
            ) => handleCreateOrder(qty, discount_percentage, discount_name)}
          />
        </>
      )}
    </div>
  );
};

export default Orders;
