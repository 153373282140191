// Contact.tsx
import React from 'react';

const Contact: React.FC = () => {
  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Contact</h2>
      <p>
        Please reach us at{' '}
        <strong>
          <a href="mailto:reseller@airlockpurifierusa.com">
            reseller@airlockpurifierusa.com
          </a>
        </strong>
        .
      </p>
    </div>
  );
};

export default Contact;
