import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Contact from './Contact';
import Orders from './Orders';
import Resources from './Resources';
import Sidebar from './Sidebar';
import { Spinner } from '@nextui-org/react';
import TopBar from './TopBar';
import { useAuth0 } from '@auth0/auth0-react';

const Dashboard: React.FC = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [isCheckingSession, setIsCheckingSession] = useState(true);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    } else if (!isLoading && isAuthenticated) {
      setIsCheckingSession(false);
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading || isCheckingSession) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <Spinner size="lg" color="secondary" />
        <p className="mt-4 text-xl font-semibold text-airlock-purple">
          Loading...
        </p>
      </div>
    );
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Router>
      <div className="flex flex-col h-screen">
        <TopBar />
        <div className="flex flex-1">
          <Sidebar />
          <div className="flex-1 p-8">
            <Routes>
              <Route path="/orders" element={<Orders />} />
              <Route path="/resources" element={<Resources />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/" element={<Navigate to="/orders" replace />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default Dashboard;
