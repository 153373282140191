import './index.css';

import { Auth0Provider } from '@auth0/auth0-react';
import { AuthTokenProvider } from './AuthTokenContext';
import Dashboard from './components/Dashboard';
import { NextUIProvider } from '@nextui-org/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Debug
// console.log(process.env.REACT_APP_AUTH0_DOMAIN);
// console.log(process.env.REACT_APP_AUTH0_CLIENT_ID);
// console.log(process.env.REACT_APP_SCOPES);
// console.log(process.env.REACT_APP_AUTH0_AUDIENCE);

root.render(
  <React.StrictMode>
    <NextUIProvider>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
        authorizationParams={{
          redirect_uri: `${window.location.origin}`,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
          scope: process.env.REACT_APP_SCOPES?.split(',').join(' ') || '',
        }}
      >
        <AuthTokenProvider>
          <Dashboard />
        </AuthTokenProvider>
      </Auth0Provider>
    </NextUIProvider>
  </React.StrictMode>
);

reportWebVitals();
