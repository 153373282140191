// services/resellerService.ts
import { ResellerInfo } from '../types';

// Fetch reseller info from Database
export const fetchResellerInfo = async (
  token: string
): Promise<ResellerInfo> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/reseller/info`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};
