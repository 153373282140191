import {
  Button,
  Image,
  Link,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
} from '@nextui-org/react';
// TopBar.tsx
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AirlockLogo from '../assets/authorized_reseller_logo.png';
import { useAuth0 } from '@auth0/auth0-react';

const TopBar: React.FC = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = ['Orders', 'Resources', 'Contact'];

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  const handleMenuItemClick = (path: string) => {
    navigate(`/${path.toLowerCase()}`);
  };

  return (
    <Navbar
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
      maxWidth="full"
      className="bg-airlock-purple text-white"
    >
      <NavbarContent justify="start">
        <NavbarMenuToggle
          aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
          className="sm:hidden"
        />
        <NavbarBrand className="flex-row items-center sm:justify-start justify-center w-full sm:w-auto hidden md:flex m-5">
          <Image
            src={AirlockLogo}
            alt="AIRLOCK Logo"
            radius="none"
            width={1500 / 7}
            height={342 / 7}
          />
        </NavbarBrand>
      </NavbarContent>
      <NavbarBrand className="md:hidden basis-28">
        <Image src={AirlockLogo} alt="AIRLOCK Logo" radius="none" />
      </NavbarBrand>

      <NavbarContent justify="end"></NavbarContent>

      <NavbarContent justify="end" className="hidden sm:flex">
        <NavbarItem>
          {isAuthenticated ? (
            <Button
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
              variant="light"
              className="text-white"
            >
              Log Out
            </Button>
          ) : (
            <Button
              onClick={() => loginWithRedirect()}
              color="success"
              variant="solid"
            >
              Log In
            </Button>
          )}
        </NavbarItem>
      </NavbarContent>
      <NavbarMenu className="pt-10">
        {menuItems.map((item, index) => (
          <NavbarMenuItem key={`${item}-${index}`}>
            <Link
              className={`w-full text-xl mb-2 text-black ${
                location.pathname === `/${item.toLowerCase()}`
                  ? 'font-bold'
                  : ''
              }`}
              onClick={() => handleMenuItemClick(item)}
              size="lg"
            >
              {item}
            </Link>
          </NavbarMenuItem>
        ))}
        <NavbarMenuItem>
          {isAuthenticated ? (
            <Link
              color={'danger'}
              className="w-full text-xl"
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
              size="lg"
            >
              Log Out
            </Link>
          ) : (
            <Link
              color={'success'}
              className="w-full text-xl"
              onClick={() => loginWithRedirect()}
              size="lg"
            >
              Log In
            </Link>
          )}
        </NavbarMenuItem>
      </NavbarMenu>
    </Navbar>
  );
};

export default TopBar;
